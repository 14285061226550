
<template>
    <div>
    </div>
</template>
<style src="./login.css" scoped></style>

<script>
// import { mapGetters } from 'vuex';
import server from '../api/server-common';
// import * as constants from '../config/constants';

export default {
    methods: {
        logout() {
            server.logout(isSuccess => {
                // logger.writeDebug(data);
                if(isSuccess) {
                    this.$store.dispatch('clearCurrentUserInfo');
                    this.$router.push('login');
                }
            });
        }
    },
    created() {
        this.logout()
    }
}

</script>
